export type TWordState = TWordStateNormal | TWordStateJail | TWordStateFame

export type TWordStateFame = {
    type: "fame",
    grade: number
}

export type TWordStateJail = {
    type: "jail",
    term: number
}

export type TWordStateNormal = {
    type: "normal",
}


export class DistributionController {
    private readonly __map__: Map<string, TWordState>
    private iterationsPassed: number
    private static readonly NORMAL_CHOOSE_PROBABILITY = 10
    private static readonly JAIL_CHOOSE_PROBABILITY = 1
    private static readonly FAMOUS_CHOOSE_PROBABILITY = 4

    constructor(words: string[]) {
        this.__map__ = new Map<string, TWordState>()
        this.chooseNextNormal = this.chooseNextNormal.bind(this)
        this.chooseNextFamous = this.chooseNextFamous.bind(this)
        this.chooseNextJailed = this.chooseNextJailed.bind(this)
        for (let w of words) {
            this.__map__.set(w, {
                type: "normal",
            })
        }
    }

    public chooseNext(): string {
        const choices = new Map<() => string, number>(
            [
                [this.chooseNextFamous, DistributionController.FAMOUS_CHOOSE_PROBABILITY],
                [this.chooseNextJailed, this.iterationsPassed > 10 ? DistributionController.JAIL_CHOOSE_PROBABILITY : 0],
                [this.chooseNextNormal, DistributionController.NORMAL_CHOOSE_PROBABILITY],
            ]
        )
        while (choices.size > 0)
        {
            const func = this.peekDistribution(choices)
            const value = func()
            if (value)
                return value
            else
                choices.delete(func)
        }
    }

    private peekDistribution<T>(candidates: Map<T, number>): T
    {
        let overallPower = 0
        candidates.forEach((power, word) => {
            overallPower += power
        })
        let cumulativeProbability = Math.floor(Math.random() * overallPower)
        for (var [key, value] of Array.from(candidates.entries())) {
            cumulativeProbability -= value
            if (cumulativeProbability <= 0)
                return key
        }
    }

    public makeDebugStateLine(): string {
        let stateLine = ""
        this.__map__.forEach((state, word) => {
            stateLine += state.type.charAt(0).toUpperCase()
        })
        return stateLine
    }

    private chooseNextFamous(): string {
        let candidates = new Map<string, TWordStateFame>()
        let samplePower = 0
        this.__map__.forEach((state, word) => {
            if (state.type == "fame") {
                candidates.set(word, state)
                samplePower += state.grade
            }
        })
        let cumulativeProbability = Math.floor(Math.random() * samplePower)
        for (var [key, value] of Array.from(candidates.entries())) {
            cumulativeProbability -= value.grade
            if (cumulativeProbability <= 0)
                return key
        }
    }

    private chooseNextJailed(): string {
        console.log("chooseNextJailed")
        let candidates = []
        this.__map__.forEach((state, word) => {
            if (state.type == "jail")
                candidates.push(word)
        })
        return candidates[Math.floor(Math.random() * candidates.length)]
    }

    private chooseNextNormal(): string {
        let candidates = []
        this.__map__.forEach((state, word) => {
            if (state.type == "normal")
                candidates.push(word)
        })
        return candidates[Math.floor(Math.random() * candidates.length)]
    }

    public feedBack(word: string, correctChoice: boolean) {
        if (!this.__map__.has(word))
            return
        let state: TWordState = this.__map__.get(word)
        if (correctChoice) {
            switch (state.type) {
                case "fame":
                    state.grade--
                    if (state.grade < 0)
                        DistributionController.stateImprison(state)
                    break;
                case "jail":
                    break;
                case "normal":
                    DistributionController.stateImprison(state)
                    break;
            }
        } else {
            switch (state.type) {
                case "fame":
                    state.grade++
                    break;
                case "jail":
                    DistributionController.stateMakeFamous(state)
                    break;
                case "normal":
                    DistributionController.stateMakeFamous(state)
                    break;
            }
        }
        this.iterationsPassed++
        this.checkPrison()
    }

    private static stateImprison(state: TWordState) {
        state.type = "jail"
        if (state.type == "jail")
            state.term = 100
    }

    private static stateMakeFamous(state: TWordState) {
        state.type = "fame"
        if (state.type == "fame")
            state.grade = 5
    }

    private static stateMakeNormal(state: TWordState) {
        state.type = "normal"
    }

    private checkPrison() {
        this.__map__.forEach(v => {
            if (v.type == "jail")
                if (--v.term <= 0)
                    DistributionController.stateMakeNormal(v)
        })
    }
}