import {styled, Toolbar,} from "@material-ui/core"
import {
    getCollapseBtn,
    getContent,
    getDrawerSidebar,
    getHeader,
    getSidebarContent,
    getSidebarTrigger,
    getStandardScheme,
    Root,
} from "@mui-treasury/layout"

import Layout from '@mui-treasury/layout';
import * as React from "react"
import HeaderEx from "./HeaderEx"
import NavContentEx from "./NavContentEx"
import TMNavHeader from "./NavHeaderEx"

interface Props {
    pages: {
        path: string
        sidebarName?: string
    }[]
}

const Header = getHeader(styled)
const Content = getContent(styled)

const scheme = Layout();

scheme.configureHeader(builder => {
    builder
        .registerConfig('xs', {
            position: 'sticky',
        })
        .registerConfig('md', {
            position: 'relative', // won't stick to top when scroll down
        });
});

scheme.configureEdgeSidebar(builder => {
    builder
        .create('unique_id', { anchor: 'left' })
        .registerTemporaryConfig('xs', {
            width: 'auto', // 'auto' is only valid for temporary variant
        });
});

export const CommonPageContainer: React.FC<Props> = (props) => {

    return (
        <Root themeProviderOmitted scheme={scheme}>
            {({state: {sidebar}}) => (
                <>
                    <Header>
                        <Toolbar>
                            {/*<SidebarTrigger sidebarId="primarySidebar"/>*/}
                            <HeaderEx/>
                        </Toolbar>
                    </Header>
                    {/*<DrawerSidebar sidebarId="primarySidebar">*/}
                    {/*    <SidebarContent>*/}
                    {/*        <TMNavHeader collapsed={sidebar.primarySidebar.collapsed}/>*/}
                    {/*        <NavContentEx/>*/}
                    {/*    </SidebarContent>*/}
                    {/*    <CollapseBtn/>*/}
                    {/*</DrawerSidebar>*/}
                    <Content>{props.children}</Content>
                </>
            )}
        </Root>
    )
}
