import {SET_USER_DATA, SET_USER_OPTIONS} from "../actions/LoadedOptionsState"

export default function loadedOptions(state = initialState, action) {
    switch (action.type) {
        case SET_USER_DATA:
            return {
                ...state,
                userData: action.payload,
            }
        case SET_USER_OPTIONS:
            return {
                ...state,
                userOptions: action.payload,
            }
    }
    return state
}

const initialState = {
    userOptions: null,
    userData: null
}
