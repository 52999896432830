import {Button, Fab, Grid} from "@material-ui/core"
import MaterialTable from "@material-table/core"
import * as React from "react"
import {API_LoadQuizDescriptors} from "../core/api/FApi"
import {showExportAlertAction} from "../core/redux/actions/PageStateActions"
import store from "../store"
import {t} from "../core/lang/lang"
import {connect} from "react-redux";
import {useHistory} from "react-router";
import CloseIcon from "@material-ui/icons/Close";

const PageQuizzes = () => {
    const [columns, setColumns] = React.useState(null)

    let history = useHistory()

    if (columns == null) {
        API_LoadQuizDescriptors().then((data) => {
            setColumns(Object.keys(data).map(key => ({
                name: key
            })))
        }).catch(() => {
        })
        return (
            <>
                <Grid container alignContent="center" spacing={1}>
                    <Grid item xs={1}/>
                    <Grid item xs={12}>
                        Loading...
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>
            </>
        )
    } else
        return (
            <>
                <Grid container spacing={1}>
                    <Grid item xs={1}/>
                    <Grid item xs={12}>
                        <MaterialTable
                            title={t("QUIZZES")}
                            columns={
                                [{title: 'Name', field: 'name'}]
                            }

                            options={{
                                pageSize: 10,
                                search: false,
                            }}

                            data={columns}

                            onRowClick={ (e, rowData) =>
                                history.push("/quiz/" + rowData["name"])
                            }
                        />
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>

            </>
        )
}

const mapStateToProps = (state) => {
    return {
        userOptions: state.loadedOptions.userOptions,
    }
}

export default connect(mapStateToProps)(PageQuizzes)
