import {API_T_QuizDescriptor} from "./FApiTypes";
import {DEF_API_URL} from "../Config";

export const API_LoadQuizDescriptors = (): Promise<API_T_QuizDescriptor[]> => {
    return fetch(DEF_API_URL + 'api/storage/descriptors.json')
        .then(response => response.json());
}

export const API_LoadQuizData = (name: string): Promise<string> => {
    return fetch(DEF_API_URL + 'api/storage/original/' + name)
        .then(response => response.text());
}
