import {Box, Chip, Fab, Grid, Theme} from "@material-ui/core"
import MaterialTable from "@material-table/core"
import * as React from "react"
import {useEffect, useRef} from "react"
import {useHistory} from "react-router-dom"
import {API_LoadQuizData} from "../core/api/FApi"
import {t} from "../core/lang/lang"
import {connect} from "react-redux";
import {useParams} from "react-router";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import {QuestionData} from "../core/quiz/QuizData";
import "./QuizPresenterStyle.css";
import {DistributionController} from "../utils/DistributionController";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                margin: theme.spacing(1)
            }
        },
        fabStyle: {
            borderColor: "white",
            borderWidth: "3px",
            backgroundColor: theme.palette.primary.light,
            color: "white",

            "&:hover": {
                backgroundColor: theme.palette.primary.light,
                color: "white"
            }
        }
    })
);

enum State {
    CHOOSE,
    CHECK_CORRECT,
    CHECK_INCORRECT,
}

// var questions: QuestionData[]

const PageQuiz = (props) => {
    const classes = useStyles();
    // const [datas, setDatas] = React.useState(null)
    // const [questions, setQuestions] = React.useState<QuestionData[]>([])
    const questions = useRef<DistributionController>()
    const [question, setQuestion] = React.useState<QuestionData>(null)
    const [state, setState] = React.useState(State.CHOOSE)
    const [selectionI, setSelectionI] = React.useState(null)

    const pickRandomQuestion = () => {
        setQuestion(new QuestionData(questions.current.chooseNext()))
    }

    const onVariantClick = (id) => {
        if (state !== State.CHOOSE)
            return
        console.log("Clck " + id)
        setSelectionI(id)
    }

    const onCheckClick = () => {
        console.log("Next clicked")
        if (state == State.CHOOSE) {
            // navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;
            let correct = selectionI === question.correctIndex;
            if (!correct && navigator.vibrate) {
                // vibration API supported
                navigator.vibrate(1000);
            }
            setState(correct ? State.CHECK_CORRECT : State.CHECK_INCORRECT)
        } else {
            questions.current.feedBack(question.original, state == State.CHECK_CORRECT)
            setState(State.CHOOSE)
            pickRandomQuestion()
            setSelectionI(null)
        }
    }

    let {quizid} = useParams<Record<string, string>>()
    let i = -1
    // console.log(question)
    if (question === null) {
        API_LoadQuizData(quizid).then((data) => {
            questions.current = new DistributionController(data.split("\n"));
            // setQuestions(questionData)
            console.log(questions.current)
            pickRandomQuestion()
            // setQuestion(questions[Math.floor(Math.random() * questions.length)])
            // setDatas(data)
        }).catch((e) => {
            console.log("fuck. ", e)
        })
        return (
            <>
                <Grid container spacing={1}>
                    <Grid item xs={1}/>
                    <Grid item xs={12}>
                        Loading...
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>
            </>
        )
    } else
        return (
            <>
                <div className={"text-line"} style={{display: "block", position: "absolute", color: "gray"}}>{questions.current.makeDebugStateLine()}</div>
                <Grid container spacing={1}>
                    <Grid item xs={1}/>
                    <Grid item xs={12} style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "calc(100vh - 200px)"
                    }}>

                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}>
                            <Typography variant="h2">
                                <Box style={state === State.CHECK_INCORRECT ? {
                                    textDecoration: "line-through",
                                    color: "grey",
                                } : state === State.CHECK_CORRECT ? {
                                    color: "green",
                                } : {
                                }}
                                     className={"text-line"}
                                     dangerouslySetInnerHTML={{__html: selectionI === null ? question.template : question.getSubstitution(selectionI)}}></Box>
                            </Typography>
                            <Typography variant="h2"
                                        className={"text-line"}
                                        style={state == State.CHOOSE ? {
                                opacity: "0%",
                            } : {
                            }} color={"primary"}>
                                <Box dangerouslySetInnerHTML={{__html: question.getCorrectSubstitution()}}></Box>
                            </Typography>
                            {/*{datas}*/}
                        </div>
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>
                <div style={{
                    position: "fixed",
                    display: "flex",
                    flexDirection: "column",
                    right: "50px",
                    bottom: "50px",
                    width: "80vw"
                }}>
                    {selectionI !== null &&
                        <Fab style={{"marginLeft": "auto", "marginBottom": "20px"}} variant="extended"
                             onClick={onCheckClick}>
                            Next
                        </Fab>}
                    <Paper style={{
                        padding: "10px",
                        display: "flex",
                        overflowX: "scroll"
                    }}>
                        {question.substitutionVariants.map(variant => {
                            let index = ++i
                            return <Fab style={{"marginLeft": "auto", "margin": "5px", minWidth: "80px"}}
                                        className={index == selectionI ? classes.fabStyle : ""}
                                        variant="extended"
                                        onClick={() => onVariantClick(index)}>
                                {variant}
                            </Fab>
                        })}
                    </Paper>
                </div>
            </>
        )
}

const mapStateToProps = (state) => {
    return {
        userOptions: state.loadedOptions.userOptions,
    }
}

export default connect(mapStateToProps)(PageQuiz)