
import {t} from "../lang/lang"
import PageQuizzes from "../../pages/PageQuizzes";
import QuizPage from "../../pages/PageQuiz";

const Routes = [
    {
        path: "/",
        sidebarName: t("MAIN"),
        icon: "report",
        perm: /table_reports_*/,
        component: PageQuizzes,
    },
    {
        path: "/quiz/:quizid",
        icon: "router",
        perm: /table_devices_*/,
        sidebarName: t("QUIZ"),
        component: QuizPage,
    },
    // {
    //     path: "/history",
    //     icon: "history",
    //     perm: /table_reports_*/,
    //     sidebarName: t("DIAGNOSTICS"),
    //     component: DiagnosticsArchive,
    // },
    // {
    //     path: "/accounts",
    //     icon: "people",
    //     perm: /table_users_*/,
    //     sidebarName: t("ACCOUNTS"),
    //     component: AccountsPage,
    // },
    // {
    //     path: "/roles",
    //     icon: "vpn_key",
    //     perm: /table_roles_*/,
    //     sidebarName: t("ROLES"),
    //     component: RolesPage,
    // },
    // // {
    // //   path: "/api",
    // //   icon: "vpn_key",
    // //   sidebarName: "API Testing",
    // //   component: APITester,
    // // },
    // {
    //     path: "/firmwares",
    //     icon: "system_update",
    //     perm: /developer_abilities/,
    //     sidebarName: t('FIRMWARES'),
    //     component: FirmwaresList,
    // },
    // {
    //     path: "/settings",
    //     perm: /settings_*/,
    //     icon: "settings",
    //     placeDelimiter: true,
    //     sidebarName: t("SETTINGS"),
    //     component: SettingsPage,
    // },
    // {
    //     path: "/accounts/edit/:userid",
    //     perm: /table_users_rw/,
    //     component: AccountEditor,
    // },
    // {
    //     path: "/roles/edit/:roleid",
    //     perm: /table_roles_rw/,
    //     component: RoleEditorPage,
    // },
    // {
    //     path: "/toir/:devid",
    //     perm: /table_devices_*/,
    //     component: TOIRPage,
    // },
    // // {
    // //     path: "/device/:devid",
    // //     perm: "table_reports_rw",
    // //     component: HistDetailPage,
    // // },
    // {
    //     path: "/accounts/edit/:userid/permissions",
    //     perm: /table_roles_*/,
    //     component: PermissionEditor,
    // },
]

export default Routes
