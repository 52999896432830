import {SET_CONN_STATE} from "../actions/ConnectionState"

export default function connState(state = initialState, action) {
    switch (action.type) {
        case SET_CONN_STATE:
            return {
                ...state,
                connState: action.payload.conn,
                reconnectCount: action.payload.reconn,
            }
    }
    return state
}

const initialState = {
    connState: WebSocket.CLOSED,
    reconnectCount: 0
}
