export const PAGE_SWITCH = "PAGE_SWITCH"

export function selectPageAction(page) {
    console.log("page " + page)
    return {
        type: PAGE_SWITCH,
        payload: page
    }
}

export const SHOW_ALERT = "SHOW_ALERT"
export const SHOW_REPORT_ALERT = "SHOW_REPORT_ALERT"
export const SHOW_DEVICE_INFO_ALERT = "SHOW_DEVICE_INFO_ALERT"

export function showAlertAction(data) {
    return {
        type: SHOW_ALERT,
        payload: data
    }
}

export function showReportAlertAction(data) {
    return {
        type: SHOW_REPORT_ALERT,
        payload: data
    }
}

export function showDeviceInfoAlertAction(data) {
    return {
        type: SHOW_DEVICE_INFO_ALERT,
        payload: data
    }
}

export const CLOSE_ALERT = "CLOSE_ALERT"

export function closeAlertAction() {
    return {
        type: CLOSE_ALERT
    }
}

export const SET_THEME = "SET_THEME"

export function setThemeAction(state) {
    return {
        type: SET_THEME,
        payload: state
    }
}

export const SHOW_EXPORT_ALERT = "SHOW_EXPORT_ALERT"

export function showExportAlertAction(kind, request) {
    return {
        type: SHOW_EXPORT_ALERT,
        payload: {kind, request}
    }
}

export function closeExportAlertAction() {
    return {
        type: CLOSE_ALERT
    }
}