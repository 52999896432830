import * as ReactDOM from "react-dom"
import {Provider} from "react-redux"
import store from "./store"
import {BrowserRouter} from "react-router-dom"

import App from "./App"
import {SnackbarProvider} from "notistack"

window.addEventListener("unhandledrejection", event => {
    console.warn(`UNHANDLED PROMISE REJECTION: ${event.reason}`);
});

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <SnackbarProvider maxSnack={3}>
                <App/>
            </SnackbarProvider>
        </Provider>
    </BrowserRouter>,
    document.getElementById("root")
)
