export const LANG_RU = {
    TEST: "тест",
    REPORTS: "Данные срабатываний ГР",
    DEVICES: "Модули",
    ACCOUNTS: "Учётные записи",
    ROLES: "Роли",
    FIRMWARES: "Прошивки",
    SETTINGS: "Настройки",
    TOIR: "Тех. обслуживание и ремонт",
    LOGOUT: "Выйти из аккаунта",
    DIAGNOSTICS: "Результаты самодиагностики",
    EDIT_PERMS: "Управление правами",
    CHANGE_PASSWORD: "Изменить пароль",
    NEW_PASSWORD: "Новый пароль"
}