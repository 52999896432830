export class QuestionData {
    public readonly original: string
    public readonly template: string
    public readonly substitutionVariants: string[] = []
    public correctIndex = -1

    constructor(srcTemplate: string) {
        this.original = srcTemplate
        let lbrace = srcTemplate.indexOf('(')
        let rbrace = srcTemplate.indexOf(')')
        let result = srcTemplate.substring(0, lbrace)
        result += '_'
        result += srcTemplate.substring(rbrace + 1)

        this.template = result
        let odd = false
        while (this.template.indexOf('*') >= 0)
        {
            odd = !odd
            this.template = this.template.replace("*", odd ? "<b>" : "</b>")
        }
        let parts = srcTemplate.substring(lbrace + 1, rbrace).split(",")
        for (let s of parts) {
            let s1 = s
            if (s.indexOf("!") >= 0) {
                this.correctIndex = this.substitutionVariants.length
                s1 = s.replace('!', ' ')
            }
            s1 = s1.trim()
            this.substitutionVariants.push(s1)
        }
    }

    public getSubstitution(choice: number): string {
        return this.template.replace("_", this.substitutionVariants[choice])
    }

    public getCorrectSubstitution(): string {
        return this.getSubstitution(this.correctIndex)
    }
}