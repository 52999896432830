export const SET_USER_DATA = "SET_USER_DATA"

export function setUserDataAction(userData) {
    return {
        type: SET_USER_DATA,
        payload: userData
    }
}

export const SET_USER_OPTIONS = "SET_USER_OPTIONS"

export function setUserOptionsAction(userOptions) {
    return {
        type: SET_USER_OPTIONS,
        payload: userOptions
    }
}