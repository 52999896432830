import {createTheme} from "@material-ui/core"

export const lightTheme = createTheme({
    palette: {
        type: "light",
    },
    overrides: {
        MuiListItem: {
            root: {
                "&.Mui-selected": {
                    backgroundColor: "#004ba0",
                    color: "#fff",
                    "& svg": {
                        fill: "green",
                        color: "#fff",
                    },
                    "&:hover": {
                        backgroundColor: "#1976d2",
                    },
                },
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 48,
            },
        },
    },
})
export const darkTheme = createTheme({
    palette: {
        type: "dark",
        primary: {
            main: '#1976d2',
            light: '#63a4ff',
            dark: '#004ba0',
        },
    },
    overrides: {
        MuiListItem: {
            root: {
                "&.Mui-selected": {
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    "& svg": {
                        fill: "green",
                        color: "#fff",
                    },
                    "&:hover": {
                        backgroundColor: "#004ba0",
                    },
                },
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 48,
            },
        },
    },
})
