import * as React from "react"
import {HashRouter, Route, Switch} from "react-router-dom"
import {CommonPageContainer} from "./common/commonPageController"
import Routes from "./core/routes/Routes"
import {closeAlertAction, closeExportAlertAction, setThemeAction,} from "./core/redux/actions/PageStateActions"
import {connect} from "react-redux"
import store from "./store"
import {darkTheme, lightTheme} from "./theme"
import {useBrowserThemeChangeListener} from 'react-use-browser-theme'
import {CssBaseline, ThemeProvider} from "@material-ui/core"
import Notifier from "./common/Notifier"
import AppWarningOverlay from "./common/AppWarningOverlay";

const App = (props) => {
    //console.log("App render " + JSON.stringify(props))

    React.useLayoutEffect(() => {
        if (matchMedia('(prefers-color-scheme: dark)').matches) {
            store.dispatch(setThemeAction(true))
        }
    }, [])

    const browserThemeListener = React.useCallback(
        (e) => store.dispatch(setThemeAction(e.matches)),
        []
    )

    useBrowserThemeChangeListener(browserThemeListener)

    return (
        <ThemeProvider theme={props.darkThemeEnabled ? darkTheme : lightTheme}>
            <CssBaseline/>
            <Notifier/>
            {/*{props.currentPage === 0 ? (*/}
            {/*    <LoginContainer/>*/}
            {/*) : (*/}
                <>
                    <HashRouter>
                        <CommonPageContainer pages={Routes}>
                            {/*<ReportAlert*/}
                            {/*    open={props.notificationType === 3}*/}
                            {/*    handleClose={() => store.dispatch(closeAlertAction())}*/}
                            {/*    data={props.notificationData}*/}
                            {/*/>*/}
                            {/*<NotificationAlert*/}
                            {/*    open={props.notificationType === 1}*/}
                            {/*    handleClose={() => store.dispatch(closeAlertAction())}*/}
                            {/*    data={props.notificationData}*/}
                            {/*/>*/}
                            {/*<ExportAlert*/}
                            {/*    open={props.notificationType === 2}*/}
                            {/*    handleClose={() => store.dispatch(closeExportAlertAction())}*/}
                            {/*    data={props.notificationData}*/}
                            {/*/>*/}
                            <Switch>
                                {Routes.map((route: any) => (
                                    <Route exact path={route.path} key={route.path}>
                                        <route.component/>
                                    </Route>
                                ))}
                            </Switch>
                        </CommonPageContainer>
                    </HashRouter>
                </>
            {/*)}*/}
            {/*<AppWarningOverlay />*/}
        </ThemeProvider>
    )
}

const mapStateToProps = (state) => {
    //console.log(JSON.stringify(state))
    return {
        darkThemeEnabled: state.currentPage.darkThemeEnabled,
    }
}

export default connect(mapStateToProps)(App)
